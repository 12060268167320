import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import LOGO_KTP from '@/assets/common/logo_ktp.png';
import LOGO from '@/assets/common/logo_background.png';
import Page1 from './page/Page1';
import Page2 from './page/Page2';
import Page3 from './page/Page3';
import Page4 from './page/Page4';
import useGetReportData from '@/hooks/report/useGetReportData';
import Cover from './page/Cover';

type Props = {
  franchiseeId: number;
  franchiseeName: string;
  title: string;
  year: string;
  month: string;
  canPrint: boolean;
};

function DownloadPDF({
  franchiseeId,
  franchiseeName,
  title,
  year,
  month,
  canPrint,
}: Props) {
  const [loading, setLoading] = useState(true);

  const {
    nationData,
    dateNationData,
    dateData,
    dayTimeData,
    priceData,
    ageGenderData,
  } = useGetReportData({ id: franchiseeId, name: franchiseeName, year, month });

  useEffect(() => {
    if (
      nationData &&
      dateNationData &&
      dateData &&
      dayTimeData &&
      priceData &&
      ageGenderData
    ) {
      setLoading(false);
    }
  }, [
    nationData,
    dateNationData,
    dateData,
    dayTimeData,
    priceData,
    ageGenderData,
  ]);

  const pageRef = useRef<any>(null);

  const reactToPrintRef = useReactToPrint({
    contentRef: pageRef,
    documentTitle: title,
  });

  useEffect(() => {
    if (canPrint && !loading) {
      setTimeout(() => {
        reactToPrintRef();
      }, 300);
    }
  }, [canPrint, loading]);

  const summaryInfo = {
    count: dateData ? dateData.totalCount : 0,
    totalPrice: dateData ? dateData.totalPrice : 0,
    totalRefund: dateData ? dateData.totalRefund : 0,
    nation: nationData ? Object.entries(nationData.details)[0][0] : '',
    date: dateData
      ? dateData.details.reduce((max: any, current: any) => {
          return current.count > max.count ? current : max;
        }).date
      : '',
  };

  const dateInfo = {
    totalCount: dateData ? dateData.totalCount : 0,
    totalPrice: dateData ? dateData.totalPrice : 0,
    totalRefund: dateData ? dateData.totalRefund : 0,
    avgCustomerTransaction: dateData ? dateData.avgCustomerTransaction : 0,
  };

  const priceInfo = [
    priceData ? priceData.totalCount : 0,
    priceData ? priceData.countByNation : {},
    priceData ? priceData.countByPriceRange : {},
  ];

  const pageList = [
    <Page1
      id={franchiseeId}
      name={franchiseeName}
      nationData={nationData?.details || {}}
      dateNationData={dateNationData?.details || {}}
      {...summaryInfo}
    />,
    <Page2 dateData={dateData?.details || []} {...dateInfo} />,
    <Page3 dayTimeData={dayTimeData?.details || []} />,
    <Page4
      priceData={priceData?.details || {}}
      ageGenderData={ageGenderData?.details || []}
      priceInfo={priceInfo}
    />,
  ];

  return (
    <Content ref={pageRef} canPrint={canPrint}>
      <Cover year={year} month={month} name={franchiseeName} />
      {loading ? (
        <Loading>데이터를 불러오는 중입니다...</Loading>
      ) : (
        pageList.map((page, index) => (
          <Page key={index}>
            <Inner>
              <Header>
                <img src={LOGO_KTP} alt='' height={48} />
                <Title>
                  {year}년 {month}월 REPORT
                </Title>
              </Header>
              <div style={{ position: 'relative', zIndex: 1 }}>{page}</div>
            </Inner>
          </Page>
        ))
      )}
    </Content>
  );
}

const Content = styled.div<{ canPrint: boolean }>`
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;

  @media print {
    size: A4 portrait;
    position: static;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    visibility: visible;
  }
`;

const Loading = styled.div`
  font-size: 16px;
  color: #3a3b3e;
  text-align: center;
  padding: 20px;
  font-weight: 600;
`;

const Page = styled.section`
  width: 21cm;
  height: 29.7cm;
  padding: 24px;
  background-color: #f6f9ff;
  color: #3a3b3e;
`;

const Inner = styled.div`
  position: relative;
  padding: 24px;
  border: 4px solid #1856cd;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    opacity: 0.1;
    background-image: url(${LOGO});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 0;
  }
`;

const Title = styled.h1`
  color: #404040;
  font-size: 48px;
  font-weight: 700;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
  margin-bottom: 20px;
`;

export default DownloadPDF;
