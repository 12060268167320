import { useState } from 'react';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import {
  getKioskFranchiseeKeywords,
  getKioskFranchiseeStatus,
} from '@/api/kiosk';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
import useHomeReducer from '@/hooks/common/useHomeReducer';
import {
  GetKioskFranchiseeStatusResponse,
  GetKioskStatusPayload,
} from '@/types/api/kiosk';

import DataTable from '@/components/common/DataTable';
import { kioskFranchiseeStatusData } from '@/constants/kiosk';
import LoadingView from '@/components/common/LoadingView';
import KioskTitle from '@/components/kiosk/kioskStatus/KioskTitle';
import KioskFranchiseeList from '@/components/kiosk/kioskStatus/KioskFranchiseeList';
import Search from '@/components/common/Search';
import ViewDateSelection from '@/components/common/ViewDataSelection';
import Pagination from '@/components/common/Pagination';
import DateSelectorFilter from '@/components/common/DateSelectorFilter';

function KtpKioskStatus() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '100';
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const { columns } = kioskFranchiseeStatusData;

  const [dispatch] = useKioskReducer();
  const [homeState, homeDispatch] = useHomeReducer();

  const {
    filter: { startDate, endDate },
  } = homeState;

  // 키워드 검색
  const { data: keywords } = useQuery(
    'kioskFranchiseeKeywords',
    getKioskFranchiseeKeywords,
    {
      retry: false,
    },
  );

  const { data: ktpKioskData, isLoading } = useQuery<
    GetKioskFranchiseeStatusResponse,
    GetKioskStatusPayload
  >(
    ['kioskFranchiseeStatus', currentPage, keyword, size, startDate, endDate],
    () =>
      getKioskFranchiseeStatus({
        page: currentPage,
        size: size,
        keyword,
        startDate: format(homeState.filter.startDate, 'yyyy-MM-dd'),
        endDate: format(homeState.filter.endDate, 'yyyy-MM-dd'),
      }),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
    },
  );

  return (
    <StyledContainer maxWidth='xl'>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <Wrapper margin='0 0 24px 0'>
            <KioskTitle title='가맹점 현황' bottomMargin='0px' />
            <Wrapper gap='8px' alignItems='stretch'>
              <ViewDateSelection
                initialSize={size}
                border='1px solid #e0e0e0e0'
                borderRadius='6px'
              />
              <DateSelectorFilter
                position='relative'
                dispatch={homeDispatch}
                filter={homeState.filter}
              />
            </Wrapper>
          </Wrapper>
          {keywords && <Search data={keywords} />}
          <DataTable
            columns={columns}
            dispatch={dispatch}
            contentComponent={
              <TableBody>
                {ktpKioskData?.content.map((item, idx) => (
                  <KioskFranchiseeList
                    key={idx}
                    idx={idx}
                    data={item}
                    size={ktpKioskData.size}
                    number={ktpKioskData.number}
                    totalElements={ktpKioskData.totalElements}
                  />
                ))}
              </TableBody>
            }
          />
          <BottomSection>
            {totalPage > 0 && (
              <Pagination
                initialPage={currentPage}
                totalPage={totalPage}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                displaySize={5}
              />
            )}
          </BottomSection>
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;

const Wrapper = styled.div<{
  margin?: string;
  flexDirection?: string;
  alignItems?: string;
  gap?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  margin: ${({ margin }) => margin || '0'};
  gap: ${({ gap }) => gap || '0'};
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

export default KtpKioskStatus;
