import styled from 'styled-components';
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import format from 'date-fns/format';
import useInputs from '../../hooks/common/useInputs';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { applyOrRemoveControlNumber } from '../../api/ktp';
import { useLocation } from 'react-router-dom';

function ControlCode() {
  const { form, onChange, onReset } = useInputs({
    valueCode: '',
    nameCode: '',
  });
  const [isTest, setIsTest] = useState(false);
  const [isApply, setIsApply] = useState(true);
  const { valueCode, nameCode } = form;

  const { pathname: currentPath } = useLocation();

  useEffect(() => {
    if (currentPath.includes('refund-test')) {
      setIsTest(true);
    } else {
      setIsTest(false);
    }
  }, [useLocation()]);

  const mutation = useMutation(
    (payload) => applyOrRemoveControlNumber(payload),
    {
      onError: (error) => {
        console.log(error);
        alert(
          `가맹점 관리코드 신청/삭제 실패\n관리자에게 문의해주세요.\nError: ${error.message}`,
        );
      },
      onSuccess: (data) => {
        alert(`가맹점 관리코드 ${isApply ? '신청' : '삭제'}가 완료되었습니다.`);
      },
    },
  );

  const onSelectChange = (event) => {
    setIsApply(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!valueCode.trim() || !nameCode.trim()) {
      alert('valueCode와 nameCode는 필수 항목입니다.');
      return;
    }

    const today = new Date();
    const payload = {
      serviceName: 'KTP',
      kindCode: 'affiliate',
      valueCode: valueCode.replace(/\s/g, ''),
      nameCode: nameCode.trim(),
      deleteYesNo: isApply ? 'N' : 'Y',
      immediateRefundYesNo: 1,
      afterRefundYesNo: 0,
      saleDate: format(today, 'yyyyMMddHHmmss') + today.getMilliseconds(),
    };

    mutation.mutate(payload);
    onReset();
  };

  return (
    <Container maxWidth='md'>
      {isTest && <Title>테스트</Title>}
      <Form method='POST' onSubmit={onSubmit}>
        <TextField
          label='valueCode'
          margin='dense'
          variant='standard'
          type='text'
          name='valueCode'
          value={valueCode}
          onChange={onChange}
          helperText={
            <>
              <div>사업자등록번호 10자리</div>
              <div>*종사업자일 경우 종사업자 번호 포함</div>
            </>
          }
          fullWidth
          style={{ marginRight: 20 }}
        />
        <TextField
          label='nameCode'
          margin='dense'
          variant='standard'
          type='text'
          name='nameCode'
          value={nameCode}
          onChange={onChange}
          helperText='어드민에 등록된 상호명'
          fullWidth
          style={{ marginRight: 20 }}
        />
        <StyledSelect
          variant='outlined'
          id='isApply'
          value={isApply}
          onChange={onSelectChange}
        >
          <MenuItem value={true}>신청</MenuItem>
          <MenuItem value={false}>삭제</MenuItem>
        </StyledSelect>
        <Button
          disabled={mutation.isLoading}
          color='primary'
          variant='contained'
          type='submit'
          style={{ height: 48 }}
        >
          요청
        </Button>
      </Form>
    </Container>
  );
}

const Form = styled.form`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  margin-right: 20px;
  height: 50px;
`;
const Title = styled.p`
  font-size: 32px;
  background-color: pink;
  color: #4b4b4b;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
`;

export default ControlCode;
