import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  initialSize: string;
  options?: number[];
  border?: string;
  borderRadius?: string;
};

function ViewDateSelection({
  initialSize,
  options = [10, 100, 500],
  border,
  borderRadius,
}: Props) {
  const updateUrlParams = useUpdateUrlParams();
  const [size, setSize] = useState(initialSize || '100');

  const onChangeSize = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSize(value);
    updateUrlParams({ size: value, page: '0' });
  };

  useEffect(() => {
    setSize(initialSize);
  }, [initialSize]);

  return (
    <Select
      value={size}
      onChange={onChangeSize}
      border={border}
      borderRadius={borderRadius}
    >
      {options.map((option) => (
        <Option key={option} value={option}>
          {option === 10000 ? '1만' : option}개씩 보기
        </Option>
      ))}
    </Select>
  );
}

const Select = styled.select<{ border?: string; borderRadius?: string }>`
  padding: 4px 8px;
  border: ${(props) =>
    props.border || '1px solid var(--KTP-Grayscale-Gray_09, #ccc)'};
  border-radius: ${(props) => props.borderRadius || '6px'};
`;
const Option = styled.option``;

export default ViewDateSelection;
