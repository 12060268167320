import { ReportResult } from '@/types/report';
import { useEffect, useState } from 'react';

type Props = {
  id: number;
  name: string;
  year: string;
  month: string;
};

function useGetReportData({ id, name, year, month }: Props) {
  const [reportData, setReportData] = useState<ReportResult | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://ktp-image.s3.ap-northeast-2.amazonaws.com/KtpReport/report/${year}/${String(
            month,
          ).padStart(2, '0')}/${id}.json`,
        );

        const data = await response.json();
        setReportData(data);
      } catch (error) {
        alert(
          `${year}년 ${month}월 ${name}에 해당하는 날짜의 매출 데이터가 존재하지 않습니다.`,
        );
      }
    };

    fetchData();
  }, [year, month, id, name]);

  return {
    nationData: reportData?.[0],
    dateNationData: reportData?.[1],
    dateData: reportData?.[2],
    dayTimeData: reportData?.[3],
    priceData: reportData?.[4],
    ageGenderData: reportData?.[5],
  };
}

export default useGetReportData;
