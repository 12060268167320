import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  GetKioskStatusDepositInfo,
  GetKioskStatusWithdrawalInfo,
} from '@/types/api/kiosk';
import {
  kioskWithdrawalStatusTitle,
  kioskWithdrawalStatusField,
  kioskDepositInfoField,
} from '@/constants/kiosk';
import { comma } from '@/utils/kiosk';

import Typography from '@/components/common/Typography';
import DateSelectorFilter from '@/components/common/DateSelectorFilter';
import CustomTableCell from '@/components/common/CustomTableCell';

type KioskRechargeStatusProps = {
  withdrawalInfo?: GetKioskStatusWithdrawalInfo;
  depositInfo?: GetKioskStatusDepositInfo;
  dispatch: React.Dispatch<any>;
  stateFilter: { endDate: Date; startDate: Date };
};

const cellStyle = {
  padding: '12px 20px',
};

const highlightedCellStyle = {
  ...cellStyle,
  backgroundColor: '#E5E6E8',
};

const formatCellContent = <T extends object>(
  info: T | undefined,
  field: string,
  isAmount: boolean,
) => {
  if (!info) return '-';
  const value = info[field as keyof T];
  return isAmount ? `${comma(value)} 원` : value;
};

function KioskWithdrawalStatus({
  withdrawalInfo,
  depositInfo,
  dispatch,
  stateFilter,
}: KioskRechargeStatusProps) {
  return (
    <>
      <Wrapper margin='0 0 20px 0'>
        <Typography fontSize='20px' fontWeight='500' lineHeight='26px'>
          현금 불출 및 충전 내역
        </Typography>
        <DateSelectorFilter
          position='relative'
          dispatch={dispatch}
          filter={stateFilter}
        />
      </Wrapper>
      <TableContainer style={{ margin: '0 0 30px 0' }}>
        <Table>
          <TableHead>
            <StyledTableRow>
              {kioskWithdrawalStatusTitle.map((title, idx) => (
                <CustomTableCell
                  key={`${title}-${idx}`}
                  width='300px'
                  style={idx === 7 ? highlightedCellStyle : cellStyle}
                >
                  {title}
                </CustomTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <CustomTableCell fontSize='14px' width='300px' style={cellStyle}>
                불출 현황
              </CustomTableCell>
              {kioskWithdrawalStatusField.map((field, idx) => (
                <CustomTableCell
                  key={field}
                  fontSize='14px'
                  width='300px'
                  style={idx === 6 ? highlightedCellStyle : cellStyle}
                >
                  {formatCellContent(withdrawalInfo, field, idx === 6)}
                </CustomTableCell>
              ))}
            </StyledTableRow>
            <StyledTableRow>
              <CustomTableCell fontSize='14px' width='300px' style={cellStyle}>
                충전 현황
              </CustomTableCell>
              {kioskDepositInfoField.map((field, idx) => (
                <CustomTableCell
                  key={field}
                  fontSize='14px'
                  width='300px'
                  style={idx === 6 ? highlightedCellStyle : cellStyle}
                >
                  {formatCellContent(depositInfo, field, idx === 6)}
                </CustomTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default KioskWithdrawalStatus;

const Wrapper = styled.div<{ margin?: string; flexDirection?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  margin: ${({ margin }) => margin || '0'};
`;

const StyledTableRow = styled(TableRow)<{ margin?: string }>`
  background-color: #ffffff;
  border-bottom: 1px solid #cbccce;
`;
