import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { ReportDateNation } from '@/types/report';
import { countToPercent, nationCodeToName } from '@/utils/report';
import { format } from 'date-fns';
import { CHART_COLORS } from '@/constants/report';

type Props = {
  data?: ReportDateNation;
};

function NationDate({ data = {} }: Props) {
  const dayData = Object.entries(data).map(([dateKey, dataValue]) => {
    const date = dateKey.split('-');
    const name = `${date[1]}/${date[2]}`;

    const nationData = Object.entries(dataValue).map(([nation, count]) => {
      return {
        nation,
        count,
      };
    });

    return { name, nationData };
  });

  const splitDate = Array.from(
    { length: Math.ceil(dayData.length / 11) },
    (_, i) => dayData.slice(i * 11, i * 11 + 11),
  );

  const nations = Object.entries(data)[0]
    ? Object.entries(Object.entries(data)[0][1]).map((data) => data[0])
    : [];

  const totalPerNation = dayData.reduce((acc, { nationData }) => {
    nationData.forEach(({ nation, count }) => {
      if (acc[nation]) {
        acc[nation] += count;
      } else {
        acc[nation] = count;
      }
    });
    return acc;
  }, {} as Record<string, number>);

  const chartData = Object.entries(data).map(([day, value]) => {
    const date = format(new Date(day), 'dd');
    const nation = Object.entries(value).map(([nation, count]) => {
      const total = Object.entries(value).reduce(
        (acc, [nation, count]) => acc + count,
        0,
      );
      const transformed = {} as Record<string, number>;
      nations.forEach((nationData) => {
        Object.entries(value).map(([nation, count]) => {
          return (transformed[nation] = countToPercent(count, total));
        });
      });

      return transformed;
    });

    return {
      name: date,
      ...nation[0],
    };
  });

  const rowSpan = Object.entries(data).length - 31 + 2;

  return (
    <Section>
      <TableWrapper>
        <TableTitle>
          일자 · 국적별<span>(상위 3개국)</span>
        </TableTitle>
        <BarWrapper>
          <BarChart
            width={690}
            height={200}
            data={chartData}
            margin={{
              top: 15,
              right: 15,
              left: -10,
              bottom: 10,
            }}
          >
            <CartesianGrid stroke='#f1f1f1' vertical={false} />
            <XAxis dataKey='name' fontSize={12} />
            <YAxis
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
              unit='%'
              fontSize={12}
            />
            <Tooltip />
            <Legend
              wrapperStyle={{ fontSize: 12 }}
              formatter={(value) => nationCodeToName(value).name}
            />
            {nations.map((nation, index) => (
              <Bar
                key={`${nation}-${index}`}
                barSize={14}
                dataKey={nation}
                stackId='a'
                fill={CHART_COLORS[index]}
                isAnimationActive={false}
              />
            ))}
          </BarChart>
        </BarWrapper>
        <TableList>
          {splitDate.map((data, index) => (
            <table key={index}>
              <thead>
                <tr>
                  <th>일자</th>
                  {nations.map((nation) => (
                    <th>{nationCodeToName(nation).name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((column, index) => (
                  <tr key={`${column.name}-${index}`}>
                    <td>{column.name}</td>
                    {column.nationData.map(({ nation, count }, index) => (
                      <td key={`${nation}-${index}`}>{count}</td>
                    ))}
                  </tr>
                ))}
                {index === splitDate.length - 1 && (
                  <>
                    <tr>
                      <th rowSpan={rowSpan} style={{ fontWeight: '400' }}>
                        합계
                      </th>
                      {Object.values(totalPerNation).map((count, index) => (
                        <td key={index} rowSpan={rowSpan}>
                          {count}
                        </td>
                      ))}
                    </tr>
                    <tr></tr>
                  </>
                )}
              </tbody>
            </table>
          ))}
        </TableList>
      </TableWrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  padding: 0 40px;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const BarWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const TableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: calc(100% / 3);

    &:not(:last-child) {
      border-right: 2px solid #bdbdbd;
    }
  }

  tr {
    > :is(th, td):first-child {
      border-left: 0;
    }
    > :is(th, td):last-child {
      border-right: 0;
    }
  }

  th,
  td {
    width: 25%;
    font-size: 14px;
    height: 22px;
    border: 1px solid lightgray;
  }
`;

export default NationDate;
